<template>
  <div class="SearchFormType">
    <div
      class="SearchFormType_wrap"
      v-for="(SearchCodeItem, index) in SearchCode"
      :key="SearchCodeItem"
    >
      <p
        style="white-space: nowrap; margin: 0 20px"
        :style="index !== 0 ? 'visibility: hidden' : ''"
      >
        项目分类：
      </p>
      <div
        class="SearchFormType_item"
        v-for="item in SearchCodeItem"
        :key="item.id"
        style="cursor: pointer"
        @click="setTenderProjectClassifyCodeSecond(item)"
        :style="
          item.dictKey == 'all' && tenderProjectClassifyCodeSecond == ''
            ? 'background:#F46C6C;color:#fff'
            : item.dictKey == tenderProjectClassifyCodeSecond
            ? 'background:#F46C6C;color:#fff'
            : ''
        "
      >
        {{ item.dictValue }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import _ from "lodash";
import { storeToRefs } from "pinia";
import { BiddingTableStore } from "@/store";
export default {
  setup() {
    // let SearchCode = [
    //   {
    //     title: "全部",
    //     code: "",
    //   },
    //   {
    //     title: "材料",
    //     code: "cailiao",
    //   },
    //   {
    //     title: "专业分包",
    //     code: "zhuanye",
    //   },
    //   {
    //     title: "劳务分包",
    //     code: "laowu",
    //   },
    //   {
    //     title: "设备周材租赁",
    //     code: "shebei",
    //   },
    //   {
    //     title: "设计、监理等服务",
    //     code: "sheji",
    //   },
    //   {
    //     title: "其他",
    //     code: "other",
    //   },
    // ];
    const SearchCode = computed(() => {
      return storeToRefs(BiddingTableStore()).projectTypeData;
    });
    const setTenderProjectClassifyCodeSecond = (item) => {
      BiddingTableStore().setData(
        "tenderProjectClassifyCodeSecond",
        item.dictKey
      );
      BiddingTableStore().reloadTableData();
    };
    const tenderProjectClassifyCodeSecond = computed(() => {
      return storeToRefs(BiddingTableStore()).tenderProjectClassifyCodeSecond;
    });
    // dictKey
    const data = reactive({
      SearchCode,
      tenderProjectClassifyCodeSecond,
    });
    return {
      ...data,
      setTenderProjectClassifyCodeSecond,
    };
  },
};
</script>

<style scoped>
@import url("./SearchFormType.css");
</style>

