<template>
  <div class="Bidding_wrap">
    <div class="Bidding_liAction">
      <div
        class="Bidding_liAction_item"
        v-for="item in LiData"
        :key="item.code"
        style="cursor: pointer"
        @click="setType(item)"
        :style="item.code == activeType ? 'background:#0074FF;color:#fff' : ''"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, reactive } from "vue";
import { BiddingTableStore } from "@/store";
import { storeToRefs } from "pinia";
export default {
  setup() {
    const LiData = [
      {
        title: "招标公告",
        code: "tender_bulletin",
      },
      {
        title: "变更公告 ",
        code: "correctional_bulletin",
      },
      {
        title: "答疑澄清",
        code: "clarify",
      },
      {
        title: "中标候选人公示",
        code: "win_candidate_publicity",
      },
      {
        title: "中标结果公示",
        code: "win_notice",
      },
      {
        title: "异常公告",
        code: "failed_notice",
      },
    ];
    const setType = (item) => {
      BiddingTableStore().setData("type", item.code);
      BiddingTableStore().reloadTableData();
    };
    const activeType = computed(() => storeToRefs(BiddingTableStore()).type);
    const data = reactive({
      LiData,
      activeType,
    });

    return {
      ...data,
      setType,
    };
  },
};
</script>

<style scoped>
@import url("./LiAction.css");
</style>

